import React, { useEffect, useCallback, useMemo } from 'react';
import { ImageDropzone } from 'react-file-utils';
import {
  ChatAutoComplete,
  EmojiPicker,
  UploadsPreview,
  useChatContext,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';
import axios from 'axios';
import type { FileUpload } from 'stream-chat-react';

import './MessagingInput.css';

import { EmojiIcon, LightningBoltSmall, SendIcon } from '../../assets';

import type { StreamChatGenerics } from '../../types';
import { useGiphyContext } from '../../Giphy';

const calcHeight = (value: string): number => {
  const miniHeight = 16;
  const lineHeight = 16;
  const padding = 16;
  const border = 0;

  const numberOfLineBreaks = (value.match(/\n/g) || []).length + 1;
  const newHeight = miniHeight + numberOfLineBreaks * lineHeight + padding + border;
  return newHeight;
}

const useDraftMessage = () => {
  const { giphyState, setGiphyState } = useGiphyContext();
  const { channel } = useChannelStateContext<StreamChatGenerics>();
  const {
    handleChange,
    handleSubmit,
    numberOfUploads,
    setText,
    text,
    textareaRef
  } = useMessageInputContext<StreamChatGenerics>();

  const channelId = useMemo<string>(() => channel.id as string, [channel]);
  const storedText: string = useMemo<string>(() => localStorage.getItem(channelId) || '', [channelId]);

  // Set draft message
  useEffect(() => {
    if (textareaRef.current) textareaRef.current.style.height = calcHeight(storedText) + 'px';
    setText(storedText);
  }, [setText, storedText, textareaRef]);

  // Save draft message
  const updateTextAreaHeightAndDraftMessage: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const { value } = event.target;

      localStorage.setItem(channelId, value);

      event.target.style.height = calcHeight(value) + 'px';

      const deletePressed =
        event.nativeEvent instanceof InputEvent &&
        event.nativeEvent.inputType === 'deleteContentBackward';

      if (text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (!giphyState && text.startsWith('/giphy') && !numberOfUploads) {
        event.target.value = value.replace('/giphy', '');
        setGiphyState(true);
      }

      handleChange(event);
    },
    [giphyState, numberOfUploads, text], // eslint-disable-line
  );

  // Delete draft message
  const onSubmit: (event: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      event.target.style.height = calcHeight('') + 'px';

      localStorage.removeItem(channelId);

      handleSubmit(event);
    }, [handleSubmit, channelId]
  );

  return { updateTextAreaHeightAndDraftMessage, onSubmit };
}

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const sendRequestToCreateVideo = ({ id, senderId, channelId, name, thumbUrl, videoUrl }: {
  id: string;
  senderId: string;
  channelId: string;
  name: string;
  thumbUrl: string;
  videoUrl: string;
}): void => {
  const path = `/api/v2/users/${senderId}/videos`;

  axios.post(baseUrl + path, { id, channel_id: channelId, name, thumb_url: thumbUrl, video_url: videoUrl });
};

const useSaveVideo = () => {
  const { client } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();
  const { fileUploads, } = useMessageInputContext<StreamChatGenerics>();

  const sendPostRequest: () => void = useCallback(() => {
    const videos = Object.values(fileUploads).filter((file: FileUpload) => file.file.type?.startsWith('video/'));

    for (const video of videos) {
      const { id, thumb_url, url, file } = video;
      sendRequestToCreateVideo({ id, senderId: client.userID!, channelId: channel.id!, name: file.name, thumbUrl: thumb_url!, videoUrl: url! });
    }
  }, [client, channel, fileUploads]);

  return { sendPostRequest };
}

const GiphyIcon = () => (
  <div className='giphy-icon__wrapper'>
    <LightningBoltSmall />
    <p className='giphy-icon__text'>GIPHY</p>
  </div>
);

const MessagingInput = () => {
  const { giphyState } = useGiphyContext();
  const {
    acceptedFiles,
    maxNumberOfFiles,
    multipleUploads,
  } = useChannelStateContext<StreamChatGenerics>();
  const {
    emojiPickerRef,
    numberOfUploads,
    openEmojiPicker,
    uploadNewFiles,
  } = useMessageInputContext<StreamChatGenerics>();
  const { updateTextAreaHeightAndDraftMessage, onSubmit } = useDraftMessage();
  const { sendPostRequest } = useSaveVideo();

  const handleSubmit : (event: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      onSubmit(event);
      sendPostRequest();
    }, [onSubmit, sendPostRequest]
  );

  return (
    <div className='str-chat__messaging-input'>
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined && numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }
      >
        <UploadsPreview />
        <div className='messaging-input__container'>
          <div
            className='messaging-input__button emoji-button'
            role='button'
            aria-roledescription='button'
            onClick={openEmojiPicker}
            ref={emojiPickerRef}
          >
            <EmojiIcon />
          </div>
          <div className='messaging-input__input-wrapper'>
            {giphyState && !numberOfUploads && <GiphyIcon />}

            <ChatAutoComplete handleSubmit={handleSubmit} onChange={updateTextAreaHeightAndDraftMessage} rows={1} placeholder='Send a message' />
          </div>
          <div
            className='messaging-input__button'
            role='button'
            aria-roledescription='button'
            onClick={handleSubmit}
          >
            <SendIcon />
          </div>
        </div>
      </ImageDropzone>
      <EmojiPicker />
    </div>
  );
};

export default MessagingInput;
