import { useEffect, useCallback } from 'react';
import { Chat, Channel, ChannelList } from 'stream-chat-react';
import {
  Attachment,
  ChannelInner,
  ChannelLists,
  MessagingChannelListHeader,
  MessagingInput,
  MessagingThreadHeader,
  CustomResultItem
} from '../components';

import { ForceReloadCounterProvider } from '../contexts/useForceReloadCounterContext';
import { HideChannelPreviewProvider } from '../contexts/useHideChannelPreviewContext';
import { SortTypeProvider } from '../contexts/useSortTypeContext';
import { useConnectUser } from '../hooks/useConnectUser';
import { useMarkUnread } from '../hooks/useMarkUnread';
import { useTheme } from '../hooks/useTheme';
import { useChecklist } from '../hooks/useChecklist';
import { useUpdateAppHeightOnResize } from '../hooks/useUpdateAppHeightOnResize';
import { GiphyContextProvider } from '../Giphy';
import { getPermission } from '../firebase';
import type { AscDesc } from 'stream-chat';
import type { StreamChatGenerics } from '../types';

type Props = {
  apiKey: string;
  userToConnect: { id: string };
  userToken: string | undefined;
  targetOrigin: string;
};

export const WebChat = (props: Props) => {
  const { apiKey, userToConnect, userToken, targetOrigin } = props;

  const chatClient = useConnectUser<StreamChatGenerics>(apiKey, userToConnect, userToken);
  const theme = useTheme(targetOrigin);
  const { markRead } = useMarkUnread();

  useChecklist(chatClient, targetOrigin);
  useUpdateAppHeightOnResize();
  getPermission();

  useEffect(() => {
    if (!chatClient) return;

    const listener = chatClient.on('message.new', (event) => {
      if (!event.channel_type) return;
      if (event?.message?.type === 'reply') return;

      const channel = chatClient.channel(event?.channel_type, event?.channel_id);
      if (channel.muteStatus().muted) return;

      if (document.visibilityState === 'hidden') {
        const notification = new Notification(event?.user?.name || 'MOMENT', { body: event?.message?.text });

        notification.onclick = function(event) {
          event.preventDefault();
          window.open(window.location.href, '_blank');
        }
      };
    });

    return () => listener.unsubscribe();
  }, [chatClient]);

  const customSearchFunction = useCallback(async (props: any, event: { target: { value: string; }; }) => { // #TODO
    const { setResults, setSearching, setQuery } = props;

    const filters = {
      members: { $in: [chatClient?.userID || ''] },
      name: { $autocomplete: event.target.value }
    }
    const options = { limit: 30 }
    const sort = { name: 1 as AscDesc };

    setSearching(true);
    setQuery(event.target.value);
    const channels = await chatClient?.queryChannels(filters, sort, options);
    if (channels !== undefined) setResults(channels);
    setSearching(false);
  }, [chatClient]);


  if (!chatClient) {
    return null; // render nothing until connection to the backend is established
  }

  return (
    <Chat client={chatClient} theme={`messaging ${theme}`}>
      <div className='messaging__container'>
        <ForceReloadCounterProvider>
          <HideChannelPreviewProvider>
            <div className='messaging__sidebar'>
              <SortTypeProvider>
                <MessagingChannelListHeader theme={theme} />
                <ChannelLists/>
              </SortTypeProvider>
            </div>
            <div className='messaging__main'>
              <div className='messaging__main__search'>
                <ChannelList
                  showChannelSearch
                  setActiveChannelOnMount={false}
                  allowNewMessagesFromUnfilteredChannels={false}
                  lockChannelOrder={true}
                  additionalChannelSearchProps={{ searchFunction: customSearchFunction, SearchResultItem: CustomResultItem }}
                  filters={{ type: 'Coaching', members: { $in: [userToConnect.id!] }, moment_channel_type: 'none' }}
                  EmptyStateIndicator={() => <></>}
                />
              </div>
              <Channel
                Attachment={Attachment}
                Input={MessagingInput}
                maxNumberOfFiles={10}
                multipleUploads={true}
                TypingIndicator={() => null}
                doMarkReadRequest={markRead}
                MessageRepliesCountButton={() => <></>}
              >
                <GiphyContextProvider>
                  <ChannelInner theme={theme} />
                </GiphyContextProvider>
              </Channel>
            </div>
          </HideChannelPreviewProvider>
        </ForceReloadCounterProvider>
      </div>
    </Chat>
  );
};
