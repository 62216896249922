import { Avatar } from 'stream-chat-react';
import './AvatarGroup.css';
import StreamLogo from '../../assets/stream.png';
import React from 'react';
import { channelIconSize } from '../Avatar/Avatar';
import { DEFAULT_USER_ICON_URL } from '../../utilities/getChannelPreviewProps';
import type { ChannelMemberResponse } from 'stream-chat';
import type { StreamChatGenerics } from '../../types';

const getImageUrl = (member: ChannelMemberResponse<StreamChatGenerics>): string => member.user!.image || DEFAULT_USER_ICON_URL;

export const AvatarGroup = ({ members }: { members: ChannelMemberResponse<StreamChatGenerics>[] }) => {
  if (members.length === 1) {
    return (
      <div className='avatar-group__avatars'>
        <Avatar image={getImageUrl(members[0])} size={channelIconSize} />
      </div>
    );
  }

  if (members.length === 2) {
    return (
      <div className='avatar-group__avatars two'>
        <span>
          <Avatar image={getImageUrl(members[0])} shape='square' size={channelIconSize} />
        </span>
        <span>
          <Avatar image={getImageUrl(members[1])} shape='square' size={channelIconSize} />
        </span>
      </div>
    );
  }

  if (members.length === 3) {
    return (
      <div className='avatar-group__avatars three'>
        <span>
          <Avatar image={getImageUrl(members[0])} shape='square' size={channelIconSize} />
        </span>
        <span>
          <Avatar image={getImageUrl(members[1])} shape='square' size={channelIconSize/2} />
          <Avatar image={getImageUrl(members[2])} shape='square' size={channelIconSize/2} />
        </span>
      </div>
    );
  }

  if (members.length >= 4) {
    return (
      <div className='avatar-group__avatars four'>
        <span>
          <Avatar image={getImageUrl(members[members.length - 1])} shape='square' size={channelIconSize/2} />
          <Avatar image={getImageUrl(members[members.length - 2])} shape='square' size={channelIconSize/2} />
        </span>
        <span>
          <Avatar image={getImageUrl(members[members.length - 3])} shape='square' size={channelIconSize/2} />
          <Avatar image={getImageUrl(members[members.length - 4])} shape='square' size={channelIconSize/2} />
        </span>
      </div>
    );
  }

  // fallback for channels with no avatars (single-user channels)
  return (
    <div className='avatar-group__avatars'>
      <Avatar image={StreamLogo} shape='square' size={channelIconSize} />
    </div>
  );
};

export default AvatarGroup;
