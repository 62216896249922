const momentUserSecureUidParamName = 'musu';

export const addUserTokenToMomentFeedbackUrl = (
  url: string,
  userId: string | undefined
): string => {
  if (!userId) return url;

  const pattern = /(^\S+:\/\/moment\.page\/f\/\S+)/;
  if (pattern.test(url)) {
    return url.replace(pattern, `$1?${momentUserSecureUidParamName}=${userId}`);
  }

  return url;
};
