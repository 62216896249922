import React from 'react';

type Props = {
  theme: string;
};

// TODO: Not use params but useContext to avoid loading
const currentUrl: string = window.location.href;
const urlParams = new URLSearchParams(window.location.search);
const page: number = parseInt(urlParams.get('page') || '1');
const getPrevUrl = (page: number): string => {
  if (page <= 2) return currentUrl.replace(/(.+)&page=[\d]+$/, "$1")

  return currentUrl.replace(/(.+&page=)[\d]+$/, `$1${page - 1}`)
}
const getNextUrl = (page: number): string => {
  if (page <= 1) return `${currentUrl}&page=2`

  return currentUrl.replace(/(.+&page=)[\d]+$/, `$1${page + 1}`)
}

const MessagingChannelListFooter = React.memo((props: Props) => {
  const { theme } = props;

  return (
    <div className={`${theme} messaging__channel-list`}>
      <div className='messaging__channel-list__footer'>
        { page > 1 && (<a className='messaging__channel-list__footer--prev messaging__channel-list__footer--link' href={getPrevUrl(page)}>Prev</a>) }
        <a className='messaging__channel-list__footer--next messaging__channel-list__footer--link' href={getNextUrl(page)}>Next</a>
      </div>
    </div>
  );
});

export default React.memo(MessagingChannelListFooter);
