import { useState, useCallback } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useChatContext } from 'stream-chat-react';

// FIXME: Take both long and short video into account
const updateInterval = 50;
const isNeededToUpdateVideoTrackingData = ({
  positionSeconds,
  durationSeconds,
}: {
  positionSeconds?: number;
  durationSeconds: number;
}): boolean => {
  if (!positionSeconds) return false;

  const completionRate = Math.floor((positionSeconds / durationSeconds) * 1000);
  if (completionRate % updateInterval !== 0) return false;

  return true;
};

const TRUE_NUMBER = 1;
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const sendRequestToUpdateVideoTrackingData = (
  userId: string,
  uri: string,
  completionRate: number
): void => {
  const path = `/api/v2/users/${userId}/video_views`;

  axios.post(baseUrl + path, { uri, completion_rate: completionRate, is_web: TRUE_NUMBER });
};

const Video = (props: any) => {
  const { url } = props;
  const { client } = useChatContext();

  const [durationSeconds, setDurationSeconds] = useState<number>(0);

  const onProgress = useCallback(({ playedSeconds }: { playedSeconds: number }) => {
    if (playedSeconds === 0) return;
    if (
      !isNeededToUpdateVideoTrackingData({
        positionSeconds: playedSeconds,
        durationSeconds
      })) return;

    const completionRate = Math.floor(
      (playedSeconds / durationSeconds) * 100
    );

    sendRequestToUpdateVideoTrackingData(client.userID!, url, completionRate);
  }, [url, client, durationSeconds]);

  return (
    <ReactPlayer {...props} onDuration={setDurationSeconds} onProgress={onProgress} />
  );
};

export default Video;
