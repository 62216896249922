import React, { useMemo, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import type { ChannelFilters } from 'stream-chat';
import type { StreamMessage } from 'stream-chat-react';
import './ChannelLists.css';
import { useSortTypeContext } from '../../contexts/useSortTypeContext';
import { useHideChannelPreviewContext } from '../../contexts/useHideChannelPreviewContext';
import { useForceReloadCounterContext, increaseChannelListsCounter } from '../../contexts/useForceReloadCounterContext';
import ChannelListsBySection from './ChannelListsBySection';
import ChannelListsByTime from './ChannelListsByTime';

const ChannelLists = () => {
  const { client } = useChatContext();
  const { sortType } = useSortTypeContext();
  const { setHideChannelIds } = useHideChannelPreviewContext();
  const counters = useForceReloadCounterContext();
  const isSortedBySections = useMemo(() => sortType === 'alphabetical', [sortType])

  useEffect(() => {
    if (!client) return;

    const listener = client.on('message.new', (event) => {
      const isThreadMessage = (message: StreamMessage) => message.type === 'reply'
      const updateForceReloadCounter = async () => {
        const filter = { members: { $in: [client.userID] }, cid: event.cid } as ChannelFilters;
        const [channel] = await client.queryChannels(filter);

        increaseChannelListsCounter({ counters, moment_channel_type: channel.data?.moment_channel_type });
      }

      if (isThreadMessage(event.message as StreamMessage)) return;
      if (!isSortedBySections) return;
      if (client.userID === event.user?.id) {
        setHideChannelIds(prev => [...prev, event.cid!]);
        return;
      }

      updateForceReloadCounter();
    });

    return () => listener.unsubscribe();
  }, [client, counters, setHideChannelIds, isSortedBySections]);

  return (
    <div className='str-chat__channel-list'>
      { isSortedBySections ?
        <ChannelListsBySection /> :
        <ChannelListsByTime />
      }
    </div>
  )
}

export default React.memo(ChannelLists);
