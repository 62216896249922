import { useChatContext, } from 'stream-chat-react';
import { Avatar } from '../';
import './CustomResultItem.css';
import { useChannelPreviewProps } from '../../hooks/useChannelPreviewProps';

import type { Channel } from 'stream-chat';
import type { SearchResultItemProps } from 'stream-chat-react';
import type { StreamChatGenerics } from '../../types';

const CustomResultItem = (props: SearchResultItemProps) => {
  const { client } = useChatContext<StreamChatGenerics>();
  const { result, selectResult } = props;

  const channel = result as Channel;
  const [displayName, imageUrl, members] = useChannelPreviewProps(client, channel);

  return (
    <div
      className='channel-search__result'
      onClick={() => selectResult(result)}
    >
      <Avatar image={imageUrl} members={members} />
      <div className='channel-search__result-info'>
        <p className='channel-search__result-text'>{displayName}</p>
        <p className='channel-search__result-message'>{channel.lastMessage()?.text || 'No message'}</p>
      </div>
    </div>
  );
}

export default CustomResultItem;
