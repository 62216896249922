import { useMemo } from 'react';
import {
  getMomentChannelData,
  getChannelPreviewDisplayName,
  getChannelPreviewAvatarImage
} from '../utilities/getChannelPreviewProps';
import type { StreamChat, Channel, ChannelMemberResponse } from 'stream-chat';
import type { StreamChatGenerics } from '../types';

export const useChannelPreviewProps = (
  client: StreamChat<StreamChatGenerics>,
  channel: Channel<StreamChatGenerics>
): [string, string | null, ChannelMemberResponse<StreamChatGenerics>[]] => {
  const members = useMemo<ChannelMemberResponse<StreamChatGenerics>[]>(() => {
    return Object.values(channel.state.members || {}).filter(
      (member) => member.user?.id !== client?.user?.id,
    );
  }, [channel, client?.user?.id]);

  const [displayName, imageUrl] = useMemo(() => {
    const {
      nameForLearner,
      nameForCoach,
      nameForFacilitator,
      iconForLearner,
      iconForCoach,
      iconForFacilitator,
      momentChannelType
    } = getMomentChannelData(channel);
    const originalDisplayName = channel.data?.name || '';
    const displayName = getChannelPreviewDisplayName({
      nameForLearner,
      nameForCoach,
      nameForFacilitator,
      originalDisplayName ,
      userType: client?.user?.type || ''
    })
    const imageUrl = getChannelPreviewAvatarImage({
      iconForLearner,
      iconForCoach,
      iconForFacilitator,
      momentChannelType,
      userType: client?.user?.type || ''
    })

    return [displayName, imageUrl]
  }, [channel, members, channel.data?.name, client?.user?.type])

  return [displayName, imageUrl, members]
};
