import { Attachment as OriginalAttachment, Card as OriginalCard, useChatContext } from 'stream-chat-react';
import type { AttachmentProps, CardProps } from 'stream-chat-react';
import Video from '../Video/Video';
import { addUserTokenToMomentFeedbackUrl } from '../../utilities/addUserTokenToMomentFeedbackUrl';
import type { StreamChatGenerics } from '../../types';

const Card = (props: CardProps) => {
  const { title_link: titleLink } = props;
  const { client } = useChatContext<StreamChatGenerics>();

  const decoratedTitleLink = titleLink
    ? addUserTokenToMomentFeedbackUrl(titleLink,client?.user?.id)
    : titleLink;

  return <OriginalCard {...props} title_link={decoratedTitleLink} />
}

const Attachment = (props: AttachmentProps<StreamChatGenerics>) => {
  return <OriginalAttachment {...props} Card={Card} Media={Video} />
}

export default Attachment;
