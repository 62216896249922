import { createContext, useContext, useState } from 'react';
import type { Dispatch, SetStateAction, ReactElement } from 'react';

type contextProps = {
  supportCounter: number;
  setSupportCounter: Dispatch<SetStateAction<number>>;
  coachingCounter: number;
  setCoachingCounter: Dispatch<SetStateAction<number>>;
  coachingTeamCounter: number;
  setCoachingTeamCounter: Dispatch<SetStateAction<number>>;
  academyCounter: number;
  setAcademyCounter: Dispatch<SetStateAction<number>>;
  otherCounter: number;
  setOtherCounter: Dispatch<SetStateAction<number>>;
}

const initialCount = 1;

export const ForceReloadCounterContext = createContext<contextProps>({} as contextProps);

export const useForceReloadCounterContext = () => {
  const contextObject = useContext(ForceReloadCounterContext);

  return { ...contextObject };
}

export const ForceReloadCounterProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  const [supportCounter, setSupportCounter] = useState<number>(initialCount);
  const [coachingCounter, setCoachingCounter] = useState<number>(initialCount);
  const [coachingTeamCounter, setCoachingTeamCounter] = useState<number>(initialCount);
  const [academyCounter, setAcademyCounter] = useState<number>(initialCount);
  const [otherCounter, setOtherCounter] = useState<number>(initialCount);
  const value = {
    supportCounter,
    setSupportCounter,
    coachingCounter,
    setCoachingCounter,
    coachingTeamCounter,
    setCoachingTeamCounter,
    academyCounter,
    setAcademyCounter,
    otherCounter,
    setOtherCounter,
  };

  return (
    <ForceReloadCounterContext.Provider value={value}>
      {children}
    </ForceReloadCounterContext.Provider>
  );
}

type functionProps = {
  counters: {
    setSupportCounter: Dispatch<SetStateAction<number>>;
    setCoachingCounter: Dispatch<SetStateAction<number>>;
    setCoachingTeamCounter: Dispatch<SetStateAction<number>>;
    setAcademyCounter: Dispatch<SetStateAction<number>>;
    setOtherCounter: Dispatch<SetStateAction<number>>;
  };
  moment_channel_type: 'learner_support' | 'learner_coaching' | 'coach_coaching_team' | 'coach_academy' | 'other' | undefined | unknown;
}
export const increaseChannelListsCounter = ({ counters, moment_channel_type }: functionProps): void => {
  switch (moment_channel_type) {
    case 'learner_support':
      counters.setSupportCounter((prev) => prev + 1);
      break;
    case 'learner_coaching':
      counters.setCoachingCounter((prev) => prev + 1);
      break;
    case 'coach_coaching_team':
      counters.setCoachingTeamCounter((prev) => prev + 1);
      break;
    case 'coach_academy':
      counters.setAcademyCounter((prev) => prev + 1);
      break;
    case 'other':
      counters.setOtherCounter((prev) => prev + 1);
      break;
    default:
      console.log(`Invalid ${moment_channel_type}`);
  }
}
