import { useState, useEffect } from 'react';
import axios from 'axios';
import { createConsumer } from '@rails/actioncable';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import './LogIn.css';

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const webSocketUrl = `${baseUrl}/cable`;
const createAuthenticationTokenUrl = `${baseUrl}/api/v2/authentication_tokens`;
const getAuthenticationUrl = `${baseUrl}/accounts/webchat_authenticate`;

type Message = {
  user_id: string;
  user_token: string;
}

const useGetAuthenticationToken = (): string | null => {
  const [authenticationToken, setAuthenticationToken] = useState<string | null>(null);

  useEffect(() => {
    axios.post(createAuthenticationTokenUrl)
      .then(function (response) {
        setAuthenticationToken(response.data.data.attributes.secure_uid);
      })
      .catch(function (error) {
        console.log(error); // TODO
      });
  }, [])

  return authenticationToken
}

const consumer = createConsumer(webSocketUrl)
const channel = 'AuthenticationTokenChannel'
const useConnectWebSocket = (authenticationToken: string | null): Message => {
  const [message, setMessage] = useState<Message>({} as Message);

  useEffect(() => {
    if (!authenticationToken) return;

    const sub = consumer.subscriptions.create({ channel, secureUid: authenticationToken }, {
      connected() { console.log('WebSocket Connected') },
      disconnected() { console.log('WebSocket Disconnected') },
      received (data: Message) { setMessage(data) }
    });

    return sub.disconnected
  }, [authenticationToken]);

  return message
}

const useRedirect = (message: Message) => {
  const { user_id, user_token } = message;

  useEffect(() => {
    if (!user_id || !user_token) return

    const url = `${window.location.origin}?user=${user_id}&user_token=${user_token}`;
    window.location.replace(url);
  }, [user_id, user_token]);
}

const getRedirectPageUrl = (authenticationToken: string) => `${getAuthenticationUrl}?token=${authenticationToken}`

export function LogIn() {
  const { t } = useTranslation();
  const authenticationToken = useGetAuthenticationToken();
  const message = useConnectWebSocket(authenticationToken);
  useRedirect(message);

  if (!authenticationToken) return <div>Loading</div>

  return (
    <div className='login__landing-wrapper'>
      <div className='login__landing-wrapper-before' />
      <div className='login__landing-wrapper-header'>
        <div className='login__landing-headerTitle'>moment web</div>
      </div>
      <div className='login__landing-window'>
        <div>
          <h1 className='login__landing-title'>{t('Login title')}</h1>
          <ol>
            <li className='login__landing-list'>{t('Login first step')}</li>
            <li className='login__landing-list'>{t('Login second step')}</li>
            <li className='login__landing-list'>{t('Login third step')}</li>
          </ol>
        </div>
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%", padding: 32, background: 'white' }}>
          <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={getRedirectPageUrl(authenticationToken)}
          viewBox={`0 0 512 512`}
          />
        </div>
      </div>
    </div>
  );
}
