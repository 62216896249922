import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import en_translation from './en.json';
import es_translation from './es.json';
import fr_translation from './fr.json';
import it_translation from './it.json';
import ja_translation from './ja.json';

const resources = {
    en: { translation: en_translation },
    es: { translation: es_translation },
    fr: { translation: fr_translation },
    it: { translation: it_translation },
    ja: { translation: ja_translation },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      fallbackLng: 'en',
      supportedLngs: ['en', 'es', 'fr', 'it', 'ja'],
      interpolation: { escapeValue: false },
      detection: {
        order: ['navigator'],
        caches: []
      }
    });

export default i18n;
