import { renderText as originalRenderText, markDownRenderers, useChatContext } from 'stream-chat-react';
import { clsx } from 'clsx';
import { addUserTokenToMomentFeedbackUrl } from '../../utilities/addUserTokenToMomentFeedbackUrl';
import type { ComponentProps } from 'react';
import type { UserResponse } from 'stream-chat';
import type { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import type { StreamChatGenerics } from '../../types';

const Anchor = ({ children, href: originalHref }: ComponentProps<'a'> & ReactMarkdownProps) => {
  const isEmail = originalHref?.startsWith('mailto:');
  const isUrl = originalHref?.startsWith('http');
  const { client } = useChatContext<StreamChatGenerics>();

  if (!originalHref || (!isEmail && !isUrl)) return <>{children}</>;
  const decoratedHref = addUserTokenToMomentFeedbackUrl(originalHref, client?.user?.id);

  return (
    <a
      className={clsx({ 'str-chat__message-url-link': isUrl })}
      href={decoratedHref}
      rel='nofollow noreferrer noopener'
      target='_blank'
    >
      {children}
    </a>
  );
};

const renderText = (text?: string,  mentionedUsers?: UserResponse<StreamChatGenerics>[]) => {
  return originalRenderText(text, mentionedUsers, {
    customMarkDownRenderers: { ...markDownRenderers, a: Anchor },
   })
}

export default renderText;
