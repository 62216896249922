import React from 'react';
import { Avatar as OriginalAvatar } from 'stream-chat-react';
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import type { ChannelMemberResponse } from 'stream-chat';
import type { StreamChatGenerics } from '../../types';

export const channelIconSize = 30;

export const Avatar = ({ image, members }: { image: string | null, members: ChannelMemberResponse<StreamChatGenerics>[] }) => {
  if (image) {
    return (
      <div className='avatar-group__avatars'>
        <OriginalAvatar image={image} size={channelIconSize} />
      </div>
    );
  }

  return <AvatarGroup members={members} /> ;
};

export default React.memo(Avatar);
