import { createContext, useContext, useState } from 'react';
import type { Dispatch, SetStateAction, ReactElement } from 'react';

export type sort = 'latest' | 'alphabetical'
type contextProps = {
  sortType:  sort;
  setSortType: Dispatch<SetStateAction<sort>>;
}

export const SortTypeContext = createContext<contextProps>({} as contextProps);

export const useSortTypeContext = () => {
  const { sortType, setSortType } = useContext(SortTypeContext);

  return { sortType, setSortType };
}

export const SortTypeProvider = ({ children }: { children: ReactElement[] }) => {
  const initialSortType: sort | null = localStorage.getItem('sortType') as sort | null; // FIXME: use context
  const [sortType, setSortType] = useState<sort>(initialSortType || 'latest');

  return (
    <SortTypeContext.Provider value={{ sortType, setSortType }}>
      {children}
    </SortTypeContext.Provider>
  );
}
