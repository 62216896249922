import { createContext, useContext, useState } from 'react';
import type { Dispatch, SetStateAction, ReactElement } from 'react';

type contextProps = {
  hideChannelIds: string[];
  setHideChannelIds: Dispatch<SetStateAction<string[]>>;
}

const initialState: string[] = [];

export const HideChannelPreviewContext = createContext<contextProps>({} as contextProps);

export const useHideChannelPreviewContext = () => {
  const contextObject = useContext(HideChannelPreviewContext);

  return { ...contextObject };
}

export const HideChannelPreviewProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  const [hideChannelIds, setHideChannelIds] = useState<string[]>(initialState);
  const value = { hideChannelIds, setHideChannelIds };

  return (
    <HideChannelPreviewContext.Provider value={value}>
      {children}
    </HideChannelPreviewContext.Provider>
  );
}
