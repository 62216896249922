import React from 'react';
import type { AscDesc } from 'stream-chat';
import { ChannelList } from 'stream-chat-react';
import type { Channel} from 'stream-chat'
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import './ChannelLists.css';
import { MessagingChannelList, MessagingChannelPreview } from '../../components';
import {
  renderUnreadSupportChannels,
  renderUnreadCoachingChannels,
  renderUnreadCoachingTeamChannels,
  renderUnreadAcademyChannels,
  renderUnreadOtherChannels,
  renderSupportChannels,
  renderCoachingChannels,
  renderCoachingTeamChannels,
  renderAcademyChannels,
  renderOtherChannels,
} from './renderChannels';
import { useChannelFilters } from '../../hooks/useChannelFilters';
import { getChannelListOptions } from '../../channelListOptions';

const offset = 30;
const urlParams = new URLSearchParams(window.location.search);
const user = urlParams.get('user') || process.env.REACT_APP_USER_ID;
const channelListOptions = getChannelListOptions(user);

const unreadChannelRenderFilter = (channels: Channel[]) => {
  return channels.filter(channel => channel.countUnread() > 0);
}
const readChannelRenderFilter = (channels: Channel[]) => {
  return channels.filter(channel => channel.countUnread() === 0);
}

const unreadChannelListProps = {
  setActiveChannelOnMount: false,
  allowNewMessagesFromUnfilteredChannels: false,
  lockChannelOrder: true,
  channelRenderFilterFn: unreadChannelRenderFilter,
  sort: { ...channelListOptions.sort, has_unread: -1 as AscDesc },
  options: channelListOptions.options,
  List: (props: any) => <MessagingChannelList {...props} />,
  Preview: (props: any) => <MessagingChannelPreview {...(props as ChannelPreviewUIComponentProps)} />,
  EmptyStateIndicator: () => <></>
}
const readChannelListProps = {
  setActiveChannelOnMount: false,
  allowNewMessagesFromUnfilteredChannels: false,
  lockChannelOrder: true,
  channelRenderFilterFn: readChannelRenderFilter,
  sort: channelListOptions.sort,
  List: (props: any) => <MessagingChannelList {...props} />,
  Preview: (props: any) => <MessagingChannelPreview {...(props as ChannelPreviewUIComponentProps)} />,
  EmptyStateIndicator: () => <></>
}

const UnreadChannelList = () => {
  const {
    supportFilters,
    coachingFilters,
    coachingTeamFilters,
    academyFilters,
    otherFilters
  } = useChannelFilters();

  return (
    <>
      <ChannelList
        {...unreadChannelListProps}
        filters={supportFilters}
        renderChannels={renderUnreadSupportChannels}
      />
      <ChannelList
        {...unreadChannelListProps}
        filters={coachingFilters}
        renderChannels={renderUnreadCoachingChannels}
      />
      <ChannelList
        {...unreadChannelListProps}
        filters={coachingTeamFilters}
        renderChannels={renderUnreadCoachingTeamChannels}
      />
      <ChannelList
        {...unreadChannelListProps}
        filters={academyFilters}
        renderChannels={renderUnreadAcademyChannels}
      />
      <ChannelList
        {...unreadChannelListProps}
        filters={otherFilters}
        renderChannels={renderUnreadOtherChannels}
      />
    </>
  );
}

const ReadChannelList = () => {
  const {
    supportFilters,
    coachingFilters,
    coachingTeamFilters,
    academyFilters,
    otherFilters
  } = useChannelFilters();

  return (
    <>
      <ChannelList
        {...readChannelListProps}
        filters={supportFilters}
        options={channelListOptions.options}
        renderChannels={renderSupportChannels}
      />
      <ChannelList
        {...readChannelListProps}
        filters={supportFilters}
        options={{ ...channelListOptions.options, offset }}
      />
      <ChannelList
        {...readChannelListProps}
        filters={coachingFilters}
        options={channelListOptions.options}
        renderChannels={renderCoachingChannels}
      />
      <ChannelList
        {...readChannelListProps}
        filters={coachingFilters}
        options={{ ...channelListOptions.options, offset }}
      />
      <ChannelList
        {...readChannelListProps}
        filters={coachingTeamFilters}
        options={channelListOptions.options}
        renderChannels={renderCoachingTeamChannels}
      />
      <ChannelList
        {...readChannelListProps}
        filters={academyFilters}
        options={channelListOptions.options}
        renderChannels={renderAcademyChannels}
      />
      <ChannelList
        {...readChannelListProps}
        filters={otherFilters}
        options={channelListOptions.options}
        renderChannels={renderOtherChannels}
      />
    </>
  );
}

const ChannelListsBySection = () => {
  return (
    <>
      <UnreadChannelList />
      <ReadChannelList />
    </>
  );
}

export default React.memo(ChannelListsBySection);
