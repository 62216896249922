import type { ChannelFilters, ChannelOptions } from 'stream-chat';
import type { AscDesc } from 'stream-chat';

/**
 * Exports few channel list configuration options. See the docs for more information:
 * - https://getstream.io/chat/docs/sdk/react/core-components/channel_list/
 *
 * @param disableChannelNameFilter set it to true if you want to see all channels where the given user is a member.
 * @param user the user id.
 */
const urlParams = new URLSearchParams(window.location.search);
const page: number = parseInt(urlParams.get('page') || '1');
const limit = 30

export const getChannelListOptions = (user: string | undefined) => {
  const filters: ChannelFilters = { type: 'Coaching', members: { $in: [user!] } }

  const options: ChannelOptions = { state: true, watch: true, presence: true, limit };

  const sort = { name: 1 as AscDesc, };

  return {
    filters,
    options,
    sort,
  };
};
