import React, { useCallback } from 'react';
import { logChatPromiseExecution } from 'stream-chat';
import {
  MessageList,
  MessageInput,
  Window,
  useChatContext,
  useChannelActionContext,
  useChannelStateContext,
} from 'stream-chat-react';
import { useTranslation } from 'react-i18next';

import { MessagingChannelHeader, MessagingInput, renderText } from '../../components';
import { useGiphyContext } from '../../Giphy';
import { useForceReloadCounterContext, increaseChannelListsCounter } from '../../contexts/useForceReloadCounterContext';
import { useMarkUnread } from '../../hooks/useMarkUnread';

import type { MessageToSend, StreamMessage } from 'stream-chat-react';
import type { StreamChatGenerics } from '../../types';

export type ChannelInnerProps = {
  theme: string;
};

export const ChannelInner = (props: ChannelInnerProps) => {
  const { theme } = props;
  const { giphyState, setGiphyState } = useGiphyContext();
  const { client } = useChatContext<StreamChatGenerics>();
  const { markUnread } = useMarkUnread();

  const { sendMessage } = useChannelActionContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();

  const counters = useForceReloadCounterContext();
  const { t } = useTranslation();
  const markUnreadMessage = useCallback(async (message: StreamMessage) => {
    // TODO: Fix after the issue is closed
    // https://github.com/GetStream/stream-chat-react/issues/1419
    if (message.user?.id === client.userID) {
      alert(t('Mark unread message'));
      return;
    }

    await markUnread(channel, message);
    increaseChannelListsCounter({ counters, moment_channel_type: channel.data?.moment_channel_type });
  }, [channel, counters, markUnread, client, t])

  // FIXME:
  // overrideSubmitHandler and shouldSubmit doesn't work because MessagingInput is not a compoment provided by Stream Chat. It should use the MessageInput compoment provided by Stream Chat
  // const overrideSubmitHandler = (message: MessageToSend<StreamChatGenerics>) => {
  //   let updatedMessage;
  //
  //   if (message.attachments?.length && message.text?.startsWith('/giphy')) {
  //     const updatedText = message.text.replace('/giphy', '');
  //     updatedMessage = { ...message, text: updatedText };
  //   }
  //
  //   if (giphyState) {
  //     const updatedText = `/giphy ${message.text}`;
  //     updatedMessage = { ...message, text: updatedText };
  //   }
  //
  //   if (sendMessage) {
  //     const newMessage = updatedMessage || message;
  //     const parentMessage = newMessage.parent;
  //
  //     const messageToSend = {
  //       ...newMessage,
  //       parent: parentMessage
  //         ? {
  //             ...parentMessage,
  //             created_at: parentMessage.created_at?.toString(),
  //             pinned_at: parentMessage.pinned_at?.toString(),
  //             updated_at: parentMessage.updated_at?.toString(),
  //           }
  //         : undefined,
  //     };
  //
  //     const sendMessagePromise = sendMessage(messageToSend);
  //     logChatPromiseExecution(sendMessagePromise, 'send message');
  //   }
  //
  //   setGiphyState(false);
  // };
  //
  // const shouldSubmit = (event: KeyboardEvent) => event.key === "Enter" && (event.metaKey || event.ctrlKey);

  const actions = ['delete', 'edit', 'react', 'quote'];

  return (
    <>
      <Window>
        <MessagingChannelHeader/>
        <MessageList
          messageActions={actions}
          customMessageActions={{ [t('Mark unread')]: markUnreadMessage }}
          renderText={renderText}
        />
        <MessageInput />
      </Window>
    </>
  );
};

export default React.memo(ChannelInner);
