import React, { PropsWithChildren } from 'react';
import type { ChannelListMessengerProps } from 'stream-chat-react';

import './MessagingChannelList.css';
import { SkeletonLoader } from './SkeletonLoader';

type Props = PropsWithChildren<ChannelListMessengerProps>

const MessagingChannelList = (props: Props) => {
  const { children, error = false, loading } = props;

  if (error) {
    return (
      <div className='messaging__channel-list__message'>
        Error loading conversations, please try again momentarily.
      </div>
    );
  }

  if (loading) {
    return (
      <div className='messaging__channel-list__message'>
        <SkeletonLoader />
      </div>
    );
  }

  return <>{children}</>;
};

export default React.memo(MessagingChannelList);
