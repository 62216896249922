import React from 'react';
import type { AscDesc } from 'stream-chat';
import { ChannelList } from 'stream-chat-react';
import dayjs from 'dayjs';
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import './ChannelLists.css';
import {  MessagingChannelList, MessagingChannelPreview } from '../../components';
import {
  renderTodayChannels,
  renderYesterdayChannels,
  renderWeekAgoChannels,
  renderMonthAgoChannels,
  renderOldChannels
} from './renderChannels';
import { useChannelFilters } from '../../hooks/useChannelFilters';
import { getChannelListOptions } from '../../channelListOptions';

const urlParams = new URLSearchParams(window.location.search);
const user = urlParams.get('user') || process.env.REACT_APP_USER_ID;
const channelListOptions = getChannelListOptions(user);

const channelListProps = {
  setActiveChannelOnMount: false,
  sort: { last_message_at: -1 as AscDesc },
  options: channelListOptions.options,
  List: (props: any) => <MessagingChannelList {...props} />,
  Preview: (props: any) => <MessagingChannelPreview {...(props as ChannelPreviewUIComponentProps) } isIncludedHideChannelPreview />,
  EmptyStateIndicator: () => <></>
}

const beginningOfToday = () => dayjs().startOf('date').toISOString();
const beginningOfYesterday = () => {
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');

  return yesterday.startOf('date').toISOString();
}
const beginningOfWeekAgo = () => {
  const today = dayjs();
  const weekAgo = today.subtract(1, 'week');

  return weekAgo.startOf('date').toISOString();
}
const beginningOfMonthAgo = () => {
  const today = dayjs();
  const monthAgo = today.subtract(1, 'month');

  return monthAgo.startOf('date').toISOString();
}

const ChannelListsByTime = () => {
  const { sortedByTimeFilters: filters } = useChannelFilters();

  return (
    <>
      <ChannelList
        {...channelListProps}
        Preview={(props: any) => <MessagingChannelPreview {...(props as ChannelPreviewUIComponentProps) } /> }
        filters={{ ...filters, last_message_at: { $gte: beginningOfToday() } }}
        renderChannels={renderTodayChannels}
      />
      <ChannelList
        {...channelListProps}
        allowNewMessagesFromUnfilteredChannels={false}
        filters={{ ...filters, $and: [{ last_message_at: { $lt: beginningOfToday() }}, { last_message_at: { $gte: beginningOfYesterday() } }] }}
        renderChannels={renderYesterdayChannels}
      />
      <ChannelList
        {...channelListProps}
        allowNewMessagesFromUnfilteredChannels={false}
        filters={{ ...filters, $and: [{ last_message_at: { $lt: beginningOfYesterday() }}, { last_message_at: { $gte: beginningOfWeekAgo() } }] }}
        renderChannels={renderWeekAgoChannels}
      />
      <ChannelList
        {...channelListProps}
        allowNewMessagesFromUnfilteredChannels={false}
        filters={{ ...filters, $and: [{ last_message_at: { $lt: beginningOfWeekAgo() }}, { last_message_at: { $gte: beginningOfMonthAgo() } }] }}
        renderChannels={renderMonthAgoChannels}
      />
      <ChannelList
        {...channelListProps}
        allowNewMessagesFromUnfilteredChannels={false}
        filters={{ ...filters, last_message_at: { $lt: beginningOfMonthAgo() } }}
        renderChannels={renderOldChannels}
      />
    </>
  );
}

export default React.memo(ChannelListsByTime);
