import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBJadyfJ-s6bF3LbIjpikRPJiAu8F9futo",
  authDomain: "moment-production-100e7.firebaseapp.com",
  projectId: "moment-production-100e7",
  storageBucket: "moment-production-100e7.appspot.com",
  messagingSenderId: "168689487147",
  appId: "1:168689487147:web:4623e3458843625135896e",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getPermission = async () => {
  const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUSH_CERTIFICATE });

  if (currentToken) {
    console.log('current token for client: ', currentToken);
    // Track the token -> client mapping, by sending to backend server
    // show on the UI that permission is secured
  } else {
    console.log('No registration token available. Request permission to generate one.');
    // shows on the UI that permission is required
  }

  return currentToken
}
