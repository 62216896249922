import React, { useState } from 'react';
import { Avatar as OriginalAvatar, useChannelStateContext, useChatContext } from 'stream-chat-react';
import Modal from '@mui/material/Modal';
import './MessagingChannelHeader.css';

import { TypingIndicator } from '../TypingIndicator/TypingIndicator';
import { ChannelInfoIcon } from '../../assets';
import StreamLogo from '../../assets/stream.png';
import { useChannelPreviewProps } from '../../hooks/useChannelPreviewProps';

import type { StreamChatGenerics } from '../../types';

// TODO: Make a component and replace 'src/components/MessagingChannelHeader.tsx#L28' with it
const MemberList = React.memo(({ image = StreamLogo as string, name }: { image: string | undefined, name: string| undefined }) => (
  <div className='messaging__channel-list__header'>
    <OriginalAvatar image={image} name={name} size={40} />
    <div className={`messaging__channel-list__header__name`}>{name}</div>
  </div>
));

const MessagingChannelHeader = () => {
  const { client } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [displayName, , ] = useChannelPreviewProps(client, channel);

  return (
    <div className='messaging__channel-header'>
      <div className='messaging__channel-header__left'>
        <span className='channel-header__name'>{displayName}</span>
      </div>
      <div className='messaging__channel-header__right'>
        <TypingIndicator />
        <ChannelInfoIcon {...{ handleOpen }} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className='messaging__channel__modal'>
            <ul className='messaging__channel__modal__container'>
              { Object.values(channel.state.members || {}).map((member, key) => (
                <MemberList key={key} image={member.user?.image} name={member.user?.name} />
              )) }
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default React.memo(MessagingChannelHeader);
