const renderChannelsFactory = (type: 'normal' | 'unread', name: string) => (loadedChannels: any[], renderChannel: any) => {
  if (loadedChannels.length === 0) return <></>;
  const sectionName = type === 'unread' ? `Unread ${name}` : name;
  const className = type === 'unread' ?
    'str-chat__channel-list__section-name unread' :
    'str-chat__channel-list__section-name';

  return (
    <>
      <span className={className}>{sectionName}</span>
      {loadedChannels.map((channel: any) => renderChannel(channel))}
    </>
  );
}

export const renderUnreadSupportChannels = renderChannelsFactory('unread', 'Support');
export const renderUnreadCoachingChannels = renderChannelsFactory('unread', 'Coaching');
export const renderUnreadCoachingTeamChannels = renderChannelsFactory('unread', 'Coaching Team');
export const renderUnreadAcademyChannels = renderChannelsFactory('unread', 'Academy');
export const renderUnreadOtherChannels = renderChannelsFactory('unread', 'Other');
export const renderSupportChannels = renderChannelsFactory('normal', 'Support');
export const renderCoachingChannels = renderChannelsFactory('normal', 'Coaching');
export const renderCoachingTeamChannels = renderChannelsFactory('normal', 'Coaching Team');
export const renderAcademyChannels = renderChannelsFactory('normal', 'Academy');
export const renderOtherChannels = renderChannelsFactory('normal', 'Other');
export const renderTodayChannels = renderChannelsFactory('normal', 'Today');
export const renderYesterdayChannels = renderChannelsFactory('normal', 'Yesterday');
export const renderWeekAgoChannels = renderChannelsFactory('normal', 'Last 7 days');
export const renderMonthAgoChannels = renderChannelsFactory('normal', 'Last 30 days');
export const renderOldChannels = renderChannelsFactory('normal', 'Before 30 days');
