import './MessagingChannelPreview.css';
import {
  ChannelPreviewUIComponentProps,
  useChatContext,
} from 'stream-chat-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeXmark } from '@fortawesome/free-solid-svg-icons'
import { clsx } from 'clsx';
import { Avatar } from '../';
import { useHideChannelPreviewContext } from '../../contexts/useHideChannelPreviewContext';
import { useChannelPreviewProps } from '../../hooks/useChannelPreviewProps';
import { useMarkUnread } from '../../hooks/useMarkUnread';

import type { StreamChat, Channel } from 'stream-chat';
import type { StreamMessage } from 'stream-chat-react';
import type { StreamChatGenerics } from '../../types';

// NOTE: Patch stream chat method
// https://github.com/GetStream/stream-chat-js/blob/master/src/channel.ts#L907
const _countMessageAsUnread = (
  message: StreamMessage<StreamChatGenerics>,
  chatClient: StreamChat<StreamChatGenerics>
) => {
  if (message.shadowed) return false;
  if (message.silent) return false;
  if (message.parent_id && !message.show_in_channel) return false;
  if (message.user?.id === chatClient.user?.id) return false;
  if (message.type === 'system') return false;

  return true;
};

// NOTE: Patch stream chat method
// https://github.com/GetStream/stream-chat-js/blob/master/src/channel.ts#L931
const countUnread = (
  chatClient: StreamChat<StreamChatGenerics>,
  channel: Channel<StreamChatGenerics>,
  lastRead?: Date | null
) => {
  if (!lastRead) return channel.state.unreadCount;

  let count = 0;
  for (let i = 0; i < channel.state.latestMessages.length; i += 1) {
    const message = channel.state.latestMessages[i];
    if (message.created_at > lastRead && _countMessageAsUnread(message, chatClient)) {
      count++;
    }
  }
  return count;
};

type Props = ChannelPreviewUIComponentProps & { isIncludedHideChannelPreview?: boolean };

const MessagingChannelPreview = (props: Props) => {
  const { channel, isIncludedHideChannelPreview } = props;
  const { channel: activeChannel, client } = useChatContext<StreamChatGenerics>();
  const { setActiveChannel, markRead } = useMarkUnread();
  const { hideChannelIds } = useHideChannelPreviewContext();
  const isMuted = channel.muteStatus().muted;
  const unreadCount = isMuted ? countUnread(client, channel, channel.lastRead()) : channel.state.unreadCount

  const [displayName, imageUrl, members] = useChannelPreviewProps(client, channel);

  if (isIncludedHideChannelPreview && hideChannelIds.includes(channel.cid!)) return <></>;

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? 'channel-preview__container selected'
          : 'channel-preview__container'
      }
      onClick={() => {
        if (isMuted && unreadCount > 0) markRead(channel); // NOTE: Update lastRead value manually for muted channels
        setActiveChannel(channel);
      }}
    >
      <Avatar image={imageUrl} members={members} />
      <div className='channel-preview__content-wrapper'>
        <div className='channel-preview__content-top'>
          <p className={clsx('channel-preview__content-name', (unreadCount > 0) && 'unread')}>
            {displayName}
          </p>
          <div className='channel-preview__details'>
            { isMuted && (
              <FontAwesomeIcon icon={faVolumeXmark} />
            )}
            { unreadCount > 0 && (
              <p className='channel-preview__content-badge'>{unreadCount}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
