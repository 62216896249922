import type { Channel } from 'stream-chat';
import type { StreamChatGenerics, MomnetChannelData } from '../types';

export const DEFAULT_USER_ICON_URL = 'https://d228c7gqd4o0kd.cloudfront.net/assets/channels/icons/user.png'

export const getMomentChannelData = (channel: Channel<StreamChatGenerics>) => {
  const {
    name_for_learner: nameForLearner,
    name_for_coach: nameForCoach,
    name_for_facilitator: nameForFacilitator,
    icon_for_learner: iconForLearner,
    icon_for_coach: iconForCoach,
    icon_for_facilitator: iconForFacilitator,
    moment_channel_type: momentChannelType
  } = channel.data as MomnetChannelData; // FIXME: Remove type assertion

  return {
    nameForLearner,
    nameForCoach,
    nameForFacilitator,
    iconForLearner,
    iconForCoach,
    iconForFacilitator,
    momentChannelType
  };
};

export const getChannelPreviewDisplayName = ({
  nameForLearner,
  nameForCoach,
  nameForFacilitator,
  originalDisplayName,
  userType,
}: {
  nameForLearner: string | null | undefined;
  nameForCoach: string | null | undefined;
  nameForFacilitator: string | null | undefined;
  originalDisplayName: string;
  userType: string;
}): string => {
  let displayName;
  if (userType === 'learner') {
    displayName = nameForLearner || originalDisplayName;
  } else if (userType === 'coach') {
    displayName = nameForCoach || originalDisplayName;
  } else if (userType === 'facilitator') {
    displayName = nameForFacilitator || originalDisplayName;
  } else {
    displayName = originalDisplayName;
  }

  return displayName;
};

export const getChannelPreviewAvatarImage = ({
  iconForLearner,
  iconForCoach,
  iconForFacilitator,
  momentChannelType,
  userType,
}: {
  iconForLearner: string | null | undefined;
  iconForCoach: string | null | undefined;
  iconForFacilitator: string | null | undefined;
  momentChannelType: string | null | undefined;
  userType: string;
}): string | null => {
  if (userType === 'learner') {
    if (iconForLearner) return iconForLearner;
    if (momentChannelType === 'learner_coaching') return DEFAULT_USER_ICON_URL;
    if (momentChannelType === 'learner_support') return DEFAULT_USER_ICON_URL;
    if (momentChannelType === 'coach_academy') return DEFAULT_USER_ICON_URL;
  } else if (userType === 'coach') {
    if (iconForCoach) return iconForCoach;
    if (momentChannelType === 'learner_coaching') return DEFAULT_USER_ICON_URL;
  } else if (userType === 'facilitator') {
    if (iconForFacilitator) return iconForFacilitator;
    if (momentChannelType === 'learner_support') return DEFAULT_USER_ICON_URL;
    if (momentChannelType === 'coach_coaching_team') return DEFAULT_USER_ICON_URL;
    if (momentChannelType === 'coach_academy') return DEFAULT_USER_ICON_URL;
  }

  return null;
};
