import { useCallback } from 'react';
import { useChatContext } from 'stream-chat-react';
import type { Channel } from 'stream-chat';
import type { StreamMessage } from 'stream-chat-react';

const preventReloadKey = 'hasMarkedUnread'

export const useMarkUnread = () => {
  const { setActiveChannel: originalSetActiveChannel } = useChatContext();

  const markRead = useCallback((channel: Channel) => {
    if (localStorage.getItem(preventReloadKey)) return;

    channel.markRead();
  }, []);

  const markUnread = useCallback(async (channel: Channel, message: StreamMessage) => {
    await channel.markUnread({ message_id: message.id });
    localStorage.setItem(preventReloadKey, 'true')
  }, []);

  const setActiveChannel = useCallback((channel: Channel) => {
    localStorage.removeItem(preventReloadKey)
    originalSetActiveChannel(channel);
  }, [originalSetActiveChannel]);

  return { markRead, markUnread, setActiveChannel };
};
