import { useMemo } from 'react';
import { getChannelListOptions } from '../channelListOptions';
import { useForceReloadCounterContext } from '../contexts/useForceReloadCounterContext';

const urlParams = new URLSearchParams(window.location.search);
const user = urlParams.get('user') || process.env.REACT_APP_USER_ID;
const channelListOptions = getChannelListOptions(user);

const filtersFactory = (type: string, counter: number) => {
  return  {
    ...channelListOptions.filters,
    member_count: { $gt : counter * -1 },
    moment_channel_type: type
  }
}

// NOTE: Need to change filter or sort to reload channels
// https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelList/hooks/usePaginatedChannels.ts#L78

export const useChannelFilters = () => {
  const {
    supportCounter,
    coachingCounter,
    coachingTeamCounter,
    academyCounter,
    otherCounter
  } = useForceReloadCounterContext();

  const supportFilters = useMemo(() => filtersFactory('learner_support', supportCounter), [supportCounter]);
  const coachingFilters = useMemo(() => filtersFactory('learner_coaching', coachingCounter), [coachingCounter]);
  const coachingTeamFilters = useMemo(() => filtersFactory('coach_coaching_team', coachingTeamCounter), [coachingTeamCounter]);
  const academyFilters = useMemo(() => filtersFactory('coach_academy', academyCounter), [academyCounter]);
  const otherFilters = useMemo(() => filtersFactory('other', otherCounter), [otherCounter]);

  const sortedByTimeFilters = useMemo(() => {
    const counter = supportCounter * coachingCounter * coachingTeamCounter * academyCounter * otherCounter;
    return {
      ...channelListOptions.filters,
      member_count: { $gt : counter  * -1 },
    }
  }, [supportCounter, coachingCounter, coachingTeamCounter, academyCounter, otherCounter]);

  return {
    supportFilters,
    coachingFilters,
    coachingTeamFilters,
    academyFilters,
    otherFilters,
    sortedByTimeFilters
  };
};
