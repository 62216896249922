import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Avatar, useChatContext } from 'stream-chat-react';
import { useSortTypeContext, sort } from '../../contexts/useSortTypeContext';

import { FilterIcon } from '../../assets/FilterIcon';
import StreamLogo from '../../assets/stream.png';

import type { StreamChatGenerics, UserTypeType } from '../../types';

const momntUserSecureUidParamName = 'musu';
const getBaseAdminUrl = (type: UserTypeType | undefined): string => {
  const empty = '';
  const adminUrl = process.env.REACT_APP_SHORTCUT_LOGIN_ADMIN_URL || '';

  if (!type) return empty

  const pair = {
    coach: adminUrl,
    facilitator: adminUrl,
    staff: adminUrl,
    learner: empty
  }

  return pair[type]
}

const getNotionUrl = (type: UserTypeType | undefined): string => {
  const empty = '';
  const notionUrl = 'https://www.notion.so/cookwithmoment/Learners-tracking-74639b5360394200af58616a64bec1bb';

  if (!type) return empty

  const pair = {
    coach: empty,
    facilitator: notionUrl,
    staff: notionUrl,
    learner: empty
  }

  return pair[type]
}

type Props = {
  theme: string;
};

const MessagingChannelListHeader = React.memo((props: Props) => {
  const { theme } = props;
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (callback?: () => void) => {
    callback?.();
    setOpen(false);
  };

  const { client } = useChatContext<StreamChatGenerics>();
  const { sortType, setSortType } = useSortTypeContext();

  const { id, image = StreamLogo as string, name = 'Example User', type } = client.user || {};
  const adminUrl = getBaseAdminUrl(type) ? `${getBaseAdminUrl(type)}?${momntUserSecureUidParamName}=${id}` : '';
  const notionUrl = getNotionUrl(type);

  const setSort = (value: sort): void => {
    localStorage.setItem('sortType', value); // FIXME: use context
    setSortType(value);
  }

  return (
    <div className={`${theme} messaging__channel-list`}>
      <div className='messaging__channel-list__header'>
        <div className='messaging__channel-list__header--profile'>
          <Avatar image={image} name={name} size={40} />
          <div>
            <div className={`${theme} messaging__channel-list__header__name`}>{name || id}</div>
            <div className='messaging__channel-list__header'>
              { adminUrl && <a href={adminUrl} className='messaging__channel-list__header__link' target='_blank' rel='noreferrer'>Dashboard</a> }
              { notionUrl && <a href={notionUrl} className='messaging__channel-list__header__link' target='_blank' rel='noreferrer'>Notion</a> }
            </div>
          </div>
        </div>
        <div className='messaging__channel-list__header--sort'>
          <FilterIcon handleOpen={handleOpen} />
          <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className='messaging__channel__modal'>
              <div className='messaging__channel__modal__container'>
                <p>Sort by</p>
                <div
                  className='messaging__channel__modal__link'
                  onClick={() => handleClose(() => setSort('alphabetical'))}
                >
                  { sortType === 'alphabetical' ? '✅ ' : '' }
                  Sections
                </div>
                <div
                  className='messaging__channel__modal__link'
                  onClick={() => handleClose(() => setSort('latest'))}
                >
                  { sortType === 'latest' ? '✅ ' : '' }
                  Recent activity
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
});

export default React.memo(MessagingChannelListHeader);
