type Props = {
  handleOpen: () => void;
};

export const FilterIcon = ({ handleOpen }: Props) => (
  <svg
    width='24'
    height='24'
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer', marginLeft: '16px' }}
    onClick={handleOpen}
  >
    <path d="M4 7H20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 12L17 12" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11 17H13" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
