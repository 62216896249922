import '@stream-io/stream-chat-css/dist/css/index.css';
import './App.css';
import { WebChat } from './pages/WebChat';
import { LogIn } from './pages/LogIn/LogIn';

type Props = {
  apiKey: string;
  userToConnect: { id: string | undefined };
  userToken: string | undefined;
  targetOrigin: string;
};

const App = (props: Props) => {
  const { apiKey, userToConnect: { id }, userToken, targetOrigin } = props;

  if (!id || !userToken) return <LogIn />

  return <WebChat apiKey={apiKey} userToConnect={{ id }} userToken={userToken} targetOrigin={targetOrigin} />
};

export default App;
